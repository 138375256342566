<template>
  <div>
    <bar-chart
      :series="getSeries"
      class="h-full"
    />
  </div>
</template>

<script>
import LandingStatsRepository from "@/repositories/LandingStatsRepository";
import BarChart from "@/components/elements/charts/BarChart.vue";

export default {
  name: "LandingNewAccountsStatsChart",
  components: {BarChart},
  data: () => ({
    responseData: []
  }),
  created() {
    LandingStatsRepository
      .getNewAccountsStats()
      .then((res) => this.responseData = res.data.data);
  },
  methods: {
    formatDate(month, year) {
      let d = this.$date();

      return d.month(month - 1).year(year).format('MMM YY');
    },
  },
  computed: {
    getSeries() {
      const series = this.responseData.flatMap(d => {
        return {
          x: this.formatDate(d.month, d.year),
          y: d.users
        }
      });

      return [{
        name: 'Clients',
        data: series
      }];
    },
  },
}
</script>

<style scoped>

</style>